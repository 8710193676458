import React, { useState, useEffect, useContext } from "react"
import { navigate, Link, graphql } from "gatsby"
import classnames from "classnames"

/* Import Global Context */
import FirstVisitContext from "~context/firstVisit"

/* Import Global Components */
import Page from "~components/page"
import PlusInCircleSvg from "~components/icon/normal/plusInCircle"
import FadeInChildElements from "~components/animations/fadeInChildElements"
import FadeInCharactersRandomly from "~components/animations/fadeInCharactersRandomly"

/* Import Local Components */
import Slides from "./landingPage/components/slides"

/* Import Slides */
import TextSlide from "./landingPage/slides/text"

/* Import Local Styles */
import "./index.css"

const Index = ({
  data: {
    sanityHomeSettings: { copy },
  },
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [firstVisit] = useContext(FirstVisitContext)
  const [isTextAnimationCompleted, setIsTextAnimationCompleted] = useState(
    false
  )

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (firstVisit) {
      navigate("/welcome/")
    }
  }, [firstVisit])

  if (firstVisit === true || firstVisit === undefined || firstVisit === null)
    return null

  return (
    <Page className="index">
      <Slides>
        <TextSlide>
          <FadeInCharactersRandomly
            className={classnames("wiggle-1", { wiggle: isLoaded })}
            copy={`We build digital and physical experiences for brands who want to be different.`}
            delay={15}
            callback={() => {
              setIsTextAnimationCompleted(true)
            }}
          />
          <FadeInChildElements start={isTextAnimationCompleted}>
            <Link
              to="/works"
              className={classnames("wiggle-2", { wiggle: isLoaded })}
            >
              Explore Work{" "}
              <span>
                <PlusInCircleSvg />
              </span>
            </Link>
          </FadeInChildElements>
        </TextSlide>
      </Slides>
    </Page>
  )
}
export default Index

export const query = graphql`
  query HomePageQuery {
    sanityHomeSettings(_id: { eq: "homeSettings" }) {
      copy
    }
  }
`
