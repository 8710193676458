import React from "react"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./text.css"

const TextSlide = ({ children }) => (
  <Slide title="text">
    <div className="text-container">
      <div>{children}</div>
    </div>
  </Slide>
)

export default TextSlide
